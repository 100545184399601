@use '~@hallmark/web.styles.util/util.module.scss' as util;
@use '~@hallmark/web.styles.sizes/sizes.module.scss' as sizes;
@use '~@hallmark/web.styles.colors/colors.module.scss' as colors;
@use 'src/styles/vars' as vars;

.address {
  display: flex;
  flex-direction: column;
  gap: util.rem(20px) 0;
  justify-content: space-between;
  align-items: flex-start;
  padding: sizes.$space-32 sizes.$space-24 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;

  .content {
    display: flex;
    gap: sizes.$space-48;
    justify-content: center;
    align-items: flex-start;
    width: 100%;

    .envelope {
      aspect-ratio: 3/2;
      flex-grow: 1;
      max-width: util.rem(900px);
      max-height: 100%;
      overflow: hidden;
      // fallback background color in case the one from the initialization response fails.
      background-color: colors.$white;
    }
  }

  @keyframes envelopeSelection {
    0% {
      opacity: 0;
      transform: translateX(-250px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  .envelope-selection {
    width: 100%;
    max-width: util.rem(600px);

    div:nth-last-of-type(1) {
      margin: auto;
      width: 95%;
      display: flex;
      align-items: flex-start;
    }

    picture {
      width: 100%;
    }

    img {
      width: 100%;
      animation: envelopeSelection 1s ease 0s 1 normal forwards;
    }
  }

  @include util.media(sizes.$mq-mobile) {
    padding: sizes.$space-24 0 0;

    .content {
      flex-direction: column;
      gap: util.rem(20px);
    }

    .envelope {
      width: calc(100% - 2 * #{sizes.$space-16});
    }
    .envelope-selection {
      min-height: util.rem(300px);

      div:nth-last-of-type(1) {
        align-items: center;
      }
    }
  }
}
// Animation
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.address-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: sizes.$space-32 sizes.$space-40;
  gap: sizes.$space-16;

  @include util.media(sizes.$mq-mobile) {
    background-color: colors.$white;
    padding: sizes.$space-12 sizes.$space-16;
    box-shadow: 0 util.rem(-2px) util.rem(10px) 0 rgba(black, 0.25);
  }

  .footer-button {
    max-width: fit-content;
    margin: 0;

    &.valid-form {
      visibility: visible;
      animation: fadeIn util.$timing-0;
    }
  }
}
